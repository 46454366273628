@use 'fontawesome/font-awesome';

html {
  background-color: #fff;
  height: 100%;
  padding: 0;
}

.anchor-with-header {
  display: block;
  padding-top: 101px;
  margin-top: -101px;

  @media(min-width: 768px) {
    padding-top: 131px;
    margin-top: -131px;
  }

  @media(min-width: 1450px) {
    padding-top: 108px;
    margin-top: -108px;
  }
}

body {
  $subscriptionColor: #4d09a5;
  --container-width: 100%;
  --header-height: 101px;
  --header-top-height: 37px;
  --universal-search-timing: 250ms;
  --color-ail: #840e46;
  --button-radius: 5px;

  background-color: inherit;
  display: flex;
  flex-direction: column;
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  height: 100%;
  padding: 0;

  @media (min-width: 768px) {
    --container-width: 750px;
    --header-height: 131px;
  }

  @media (min-width: 992px) {
    --container-width: 970px;
  }

  @media (min-width: 1200px) {
    --container-width: 1170px;
  }

  @media (min-width: 1350px) {
    --header-height: 108px;
  }

  main {
    flex: 1;
    margin-top: var(--header-height, 131px);

    @media print {
      margin-top: 0;
    }

    .notice {
      z-index: 1;
      position: absolute;
      max-width: 800px;
      justify-self: anchor-center;
    }
  }
}
